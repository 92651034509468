import React from 'react';
import { Link } from 'react-router-dom'

import Header from "../../components/Layout/Header";
import Footer from "../../components/Layout/Footer";
import WarehouseService from '../../components/Layout/WarehouseService';
import { Helmet } from 'react-helmet';
import strings from '../../components/Language';

class ContactUsScreen extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
		}
	}

	async componentDidMount() {
		window.scrollTo(0, 0);
	}

	render() {
		return (
			<>
				<Helmet><title>{"Device Warehouse | " + strings["Locate Us"]}</title></Helmet>
				<Header />

				<div className="container">
					<div className="mt-5">
						<div className="col-12 text-center mb-3">
							<h2>{strings["Locate Us"]}</h2>
						</div>
						<div className="row">
							<div className="col-md-6 col-sm-12 mb-3">
								<img src="assets/images/rk.jpg" alt="" />
								<p>
									<div style={{ marginBottom: 10 }}>
										{strings["Kheha Romklao Branch, Bangkok"]}<br />
										<a href="https://www.google.com/maps/search/?api=1&query=13.7910131,100.5998317">View on Google Map</a>
									</div>
								</p>
							</div>
							<div className="col-md-6 col-sm-12 mb-3">
								<img src="assets/images/cnx.jpg" alt="" />
								<p>
									<div>
										{strings["Chiang Mai Branch"]}<br />
										<a href="https://www.google.com/maps/place/18°48'05.4%22N+98°58'42.6%22E/@18.8015024,98.9759324,17z/data=!3m1!4b1!4m4!3m3!8m2!3d18.8015024!4d98.9785073?entry=ttu">
											View on Google Map
										</a>
									</div>
								</p>
							</div>
							<div className="col-md-6 col-sm-12 mb-3">
								<img src="assets/images/branch_003.jpg" alt="" />
								<p className='mt-3'>
									<div>
										{"Device warehouse หจก.ดีไวซ์แวร์เฮาส์ 290 s-sense 119 บ้านกลางเมือง ถ.พระราม 9-ลาดพร้าว Khwaeng Wang Thonglang, Wang Thonglang, Bangkok 10130, Thailand"}<br />
										<a href="https://www.google.com/maps/place/Device+warehouse+%E0%B8%AB%E0%B8%88%E0%B8%81.%E0%B8%94%E0%B8%B5%E0%B9%84%E0%B8%A7%E0%B8%8B%E0%B9%8C%E0%B9%81%E0%B8%A7%E0%B8%A3%E0%B9%8C%E0%B9%80%E0%B8%AE%E0%B8%B2%E0%B8%AA%E0%B9%8C/@13.7737659,100.6035808,17z/data=!3m1!4b1!4m6!3m5!1s0x30e29d4ddd08a78d:0xcdeec59ce90571a5!8m2!3d13.7737659!4d100.6035808!16s%2Fg%2F11v17jpk6q?entry=ttu&g_ep=EgoyMDI1MDEwOC4wIKXMDSoASAFQAw%3D%3D">
											View on Google Map
										</a>
									</div>
								</p>
							</div>

							<div className="col-md-6 col-sm-12 mb-3">
								<img src="assets/images/branch_004.jpg" alt="" />
								<p className='mt-3'>
									<div>
										{"245F+FV4 มาบยางพร 39/5 Map Yang Phon, Pluak Daeng District, Rayong 21140, Thailand"}<br />
										<a href="https://www.google.com/maps/place/%E0%B8%95%E0%B8%A5%E0%B8%B2%E0%B8%94%E0%B8%AA%E0%B8%B0%E0%B8%9E%E0%B8%B2%E0%B8%99%E0%B8%AA%E0%B8%B5%E0%B9%88%E0%B8%9E%E0%B8%A5%E0%B8%B2%E0%B8%8B%E0%B9%88%E0%B8%B2/@13.0086443,101.1246949,17z/data=!3m1!4b1!4m6!3m5!1s0x3102c3cdc9ec4c13:0x792bb549184269f!8m2!3d13.0086443!4d101.1246949!16s%2Fg%2F11vdp329x6?entry=ttu&g_ep=EgoyMDI1MDEwOC4wIKXMDSoASAFQAw%3D%3D">
											View on Google Map
										</a>
									</div>
								</p>
							</div>

						</div>
						<div className="col-md-6 col-sm-12 mb-3 ps">
							<h1>{strings["Our Channels"]}</h1>
							<p>
								{strings["Line OA"]}:  <a href='https://line.me/R/ti/p/@490zukar'>@DeviceWH</a><br />
								{strings["Facebook Page"]} : <a href='https://www.facebook.com/people/Device-Warehouse/100092512337529/?mibextid=LQQJ4d'>Device Warehouse</a>
							</p>
						</div>
					</div>
				</div>

				<WarehouseService />
				<Footer />
			</>
		);
	}
}

export default ContactUsScreen;
